<<template>
    <div class="paper-detail">
        <div class="title">
            <div class="back">
                <el-button el-button type="primary" @click="back">
                    <i class="el-icon-plus" style="margin-right: 5px"></i>返回
                </el-button>
            </div>
            {{ paperName }}
        </div>
        <div class="scroll-content">
            <el-scrollbar style="height: 100%;">
                <PaperPreview :paperDetail="detailData"></PaperPreview>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
import { getIdentifyCenterTheoryDetail } from "@/utils/apis";
import PaperPreview from "components/evaluation/examPaperManage/PaperPreview";

export default {
    name: "PaperDetail",
    data() {
        return {
            paperName: "",
            detailData: {},
        };
    },
    components: {
        PaperPreview,
    },
    methods: {
        back() {
            this.$router.back();
        },
        async init() {
            let typeText = ["single_data", "multiple_data", "judge_data", "fillBlank_data", "askAnswer_data"];
            let id = this.$route.query.id;
            let res = await getIdentifyCenterTheoryDetail({ check_center_paper_id: id });
            if (res.code === 200) {
                this.paperName = res.data.theory_test_paper_data.name;
                let totalList = res.data.total_list;
                let obj = {};
                res.data.theory_test_paper_data.test_paper_topic_data.forEach((itemI, index) => {
                    let question = totalList.find((itemJ) => Number(itemJ.theory_topic_id) === Number(itemI.theory_topic_id));
                    if (question !== undefined) {
                        question.options = JSON.parse(question.options);
                        let typeKey = typeText[question.type_id - 1];
                        if (!obj[typeKey]) {
                            obj[typeKey] = {};
                            obj[typeKey].list = [];
                            obj[typeKey].sum = 0;
                        }
                        obj[typeKey].list.push({
                            ...question,
                            ...itemI,
                        });
                        obj[typeKey].sum++;
                    }
                });
                typeText.forEach((item) => {
                    if (obj[item]) {
                        obj[item].list = obj[item].list.sort((a, b) => a.theory_topic_id - b.theory_topic_id);
                    }
                });
                for (const key in obj) {
                    obj[key].list.forEach((item) => {
                        if (!item.hasOwnProperty("student_answer")) {
                            this.$set(item, "student_answer", []);
                            if (key === "single_data" || key === "judge_data" || key === "askAnswer_data") {
                                item.student_answer = [""];
                            }
                            if (key === "multiple_data") {
                                item.student_answer = [];
                            }
                            if (key === "fillBlank_data") {
                                for (let i = 0; i < item.options.length; i++) {
                                    let tmp = {
                                        name: "",
                                    };
                                    item.student_answer.push(tmp);
                                }
                            }
                        } else {
                            if (key === "fillBlank_data") {
                                let list = [];
                                for (let i = 0; i < item.student_answer.length; i++) {
                                    let tmp = {
                                        name: item.student_answer[i],
                                    };
                                    list.push(tmp);
                                }
                                item.student_answer = list;
                            }
                        }
                    });
                }
                // this.paperInfo.theory_topic_data = res.data.theory_topic_data;
                console.log('obj', obj);
                this.detailData = obj;
            } else {
                this.$message.error(res.msg);
                this.$router.back();
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style scoped lang="scss">
.paper-detail {
    height: 100%;
    padding-bottom: 100px;
    .title {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 40px;
        padding-bottom: 20px;
        font-size: 25px;
        text-align: center;
        border-bottom: 1px solid #eee;
        .back {
            position: absolute;
            left: 30px;
            top: 0;
        }
    }
    .scroll-content {
        height: 100%;
        overflow: hidden;
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
</style>
